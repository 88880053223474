<template>
  <div class="container">
    <h2>Registration</h2>
    <pre v-if="response">{{ response }}</pre>
    <form @submit.prevent="handleSubmit">
      <div>
        <label>User email</label>
        <input v-model="userEmail" type="text" name="userEmail" class="form-control"/>
      </div>
      <div>
        <label>User password</label>
        <input v-model="userPass" type="password" name="userPass"/>
      </div>
      <div>
        <label>User password</label>
        <input v-model="userPassConfirm" type="password" name="userPassConfirm"/>
      </div>
      <div>
        <button :disabled="submitted" :class="submitted ? 'is-load' : ''">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>

import {WSEventService} from "../../_services/ws-events.service";
import apiService from "../../_services/api.service";

export default {
  data: () => {
    return {
      userEmail: '',
      userPass: '',
      userPassConfirm: '',
      submitted: false,
      response: null
    }
  },
  methods: {
    handleSubmit() {
      if (!(this.userEmail || this.userPass || this.userPassConfirm)) {
        return;
      }
      this.submitted = true;
      let form = new FormData;
      form.append("user_email", this.userEmail);
      form.append("user_pass", this.userPass);
      form.append("user_pass_confirm", this.userPassConfirm);
      apiService.post("users/create", form).then(
          payload => {
            this.userEmail = '';
            this.userPass = '';
            this.userPassConfirm = '';
            this.response = payload.data;
          }
      ).catch(
          error => {
            this.response = error.response.data;
          }).finally(
          () => {
            this.submitted = false;
          });
    },
  },
  created() {
    WSEventService.subscribeEvents();
  }
};
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>